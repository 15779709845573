<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
            src="@/assets/images/profile-bg.jpg"
            alt=""
            class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img
                :src="this.URL +'/'+ usuario.Photo"
                alt="user-img"
                class="img-thumbnail rounded-circle"
            />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{ usuario.Nome }}</h3>
            <p class="text-white-75">{{ usuario.CPF }}
              <br> {{ usuario.perfil_descricao }}
            </p>
            <div class="hstack text-white-50 gap-1">
              <div class="me-2">
                <i
                    class="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >{{ usuario.UF }}
              </div>
              <div>
                <i
                    class="ri-building-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >{{ usuario.NomeProfissional }}
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-12 col-lg-auto order-last order-lg-0">
          <div class="row text text-white-50 text-center">
            <div class="col-lg-6 col-4">
              <div class="p-2">

              </div>
            </div>
            <div class="col-lg-6 col-4">
              <div class="p-2">

              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">

            <b-tabs content-class="mt-3" class="nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1">
              <b-tab title="Cadastro" active>
                <div class="row">
                  <div class="col-xxl-3">

                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title mb-3">
                          <div class="row">
                            <div class="col-sm-9">
                              Informações
                            </div>
                            <div class="col-sm-3">
                              <b-button class="btn btn-warning btn-sm shadow-sm"
                              @click="enviarEmail">
                                Email
                              </b-button>
                            </div>
                          </div>

                        </h5>
                        <div class="table-responsive">
                          <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                              <th class="ps-0" scope="row">Perfil :</th>
                              <td class="text-muted">{{ usuario.perfil_descricao }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Nome :</th>
                              <td class="text-muted">{{ usuario.Nome }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Nome profissional:</th>
                              <td class="text-muted">{{ usuario.NomeProfissional }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">CPF :</th>
                              <td class="text-muted">{{ usuario.CPF }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">CNPJ :</th>
                              <td class="text-muted">{{ usuario.CNPJ }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Telefone :</th>
                              <td class="text-muted">{{ usuario.Celular }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">E-mail :</th>
                              <td class="text-muted">{{ usuario.email }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Data de nascimento</th>
                              <td class="text-muted">{{getHumanDate(usuario.DataNascimento)}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!--end col-->
                  <div class="col-xxl-9">

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 me-2">Atividades recentes</h4>
                            <div class="flex-shrink-0 ms-auto">
                              <ul
                                  class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                  role="tablist"
                              >
                                <li class="nav-item">
                                  <a
                                      class="nav-link active"
                                      data-bs-toggle="tab"
                                      href="#today"
                                      role="tab"
                                  > Registros </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="tab-content text-muted">
                              <div
                                  class="tab-pane active"
                                  id="today"
                                  role="tabpanel"
                              >
                                <div class="profile-timeline">
                                  <div
                                      class="accordion accordion-flush"
                                      id="todayExample"
                                  >
                                    <div class="accordion-item border-0">
                                      <div
                                          class="accordion-header"
                                          id="headingOne"
                                      >
                                        <a
                                            class="accordion-button p-2 shadow-none"
                                            data-bs-toggle="collapse"
                                            href="#collapseOne"
                                            aria-expanded="true"
                                        >
                                          <div class="d-flex">
                                            <div class="flex-shrink-0">
                                              <img
                                                  src="@/assets/images/users/avatar-2.jpg"
                                                  alt=""
                                                  class="avatar-xs rounded-circle"
                                              />
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h6 class="fs-14 mb-1">
                                                Jacqueline Steve
                                              </h6>
                                              <small class="text-muted"
                                              >We has changed 2 attributes on 05:16PM</small
                                              >
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                      <div
                                          id="collapseOne"
                                          class="accordion-collapse collapse show"
                                          aria-labelledby="headingOne"
                                          data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body ms-2 ps-5">
                                          In an awareness campaign, it is vital for people to begin put 2 and 2 together
                                          and begin to recognize your cause. Too much or too little spacing, as in the
                                          example below, can make things unpleasant for the reader. The goal is to make
                                          your text as comfortable to read as possible. A wonderful serenity has taken
                                          possession of my entire soul, like these sweet mornings of spring which I
                                          enjoy with my whole heart.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item border-0">
                                      <div
                                          class="accordion-header"
                                          id="headingTwo"
                                      >
                                        <a
                                            class="accordion-button p-2 shadow-none"
                                            data-bs-toggle="collapse"
                                            href="#collapseTwo"
                                            aria-expanded="false"
                                        >
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 avatar-xs">
                                              <div
                                                  class="avatar-title bg-light text-success rounded-circle"
                                              >
                                                M
                                              </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h6 class="fs-14 mb-1">
                                                Megan Elmore
                                              </h6>
                                              <small class="text-muted"
                                              >Adding a new event with attachments - 04:45PM</small
                                              >
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                      <div
                                          id="collapseTwo"
                                          class="accordion-collapse collapse show"
                                          aria-labelledby="headingTwo"
                                          data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body ms-2 ps-5">
                                          <div class="row g-2">
                                            <div class="col-auto">
                                              <div
                                                  class="d-flex border border-dashed p-2 rounded position-relative"
                                              >
                                                <div class="flex-shrink-0">
                                                  <i
                                                      class="ri-image-2-line fs-17 text-danger"
                                                  ></i>
                                                </div>
                                                <div class="flex-grow-1 ms-2">
                                                  <h6>
                                                    <a
                                                        href="javascript:void(0);"
                                                        class="stretched-link"
                                                    >Business Template - UI/UX design</a
                                                    >
                                                  </h6>
                                                  <small>685 KB</small>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-auto">
                                              <div
                                                  class="d-flex border border-dashed p-2 rounded position-relative"
                                              >
                                                <div class="flex-shrink-0">
                                                  <i
                                                      class="ri-file-zip-line fs-17 text-info"
                                                  ></i>
                                                </div>
                                                <div class="flex-grow-1 ms-2">
                                                  <h6>
                                                    <a
                                                        href="javascript:void(0);"
                                                        class="stretched-link"
                                                    >Bank Management System - PSD</a
                                                    >
                                                  </h6>
                                                  <small>8.78 MB</small>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item border-0">
                                      <div
                                          class="accordion-header"
                                          id="headingThree"
                                      >
                                        <a
                                            class="accordion-button p-2 shadow-none"
                                            data-bs-toggle="collapse"
                                            href="#collapsethree"
                                            aria-expanded="false"
                                        >
                                          <div class="d-flex">
                                            <div class="flex-shrink-0">
                                              <img
                                                  src="@/assets/images/users/avatar-5.jpg"
                                                  alt=""
                                                  class="avatar-xs rounded-circle"
                                              />
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h6 class="fs-14 mb-1">
                                                New ticket received
                                              </h6>
                                              <small class="text-muted mb-2"
                                              >User <span class="text-secondary"
                                              >Erica245</span
                                              > submitted a ticket - 02:33PM</small
                                              >
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div class="accordion-item border-0">
                                      <div
                                          class="accordion-header"
                                          id="headingFour"
                                      >
                                        <a
                                            class="accordion-button p-2 shadow-none"
                                            data-bs-toggle="collapse"
                                            href="#collapseFour"
                                            aria-expanded="true"
                                        >
                                          <div class="d-flex">
                                            <div class="flex-shrink-0 avatar-xs">
                                              <div
                                                  class="avatar-title bg-light text-muted rounded-circle"
                                              >
                                                <i class="ri-user-3-fill"></i>
                                              </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h6 class="fs-14 mb-1">
                                                Nancy Martino
                                              </h6>
                                              <small class="text-muted"
                                              >Commented on 12:57PM</small
                                              >
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                      <div
                                          id="collapseFour"
                                          class="accordion-collapse collapse show"
                                          aria-labelledby="headingFour"
                                          data-bs-parent="#accordionExample"
                                      >
                                        <div
                                            class="accordion-body ms-2 ps-5 fst-italic"
                                        >
                                          " A wonderful serenity has taken possession of my entire soul, like these
                                          sweet mornings of spring which I enjoy with my whole heart. Each design is a
                                          new, unique piece of art birthed into this world, and while you have the
                                          opportunity to be creative and make your own style choices. "
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item border-0">
                                      <div
                                          class="accordion-header"
                                          id="headingFive"
                                      >
                                        <a
                                            class="accordion-button p-2 shadow-none"
                                            data-bs-toggle="collapse"
                                            href="#collapseFive"
                                            aria-expanded="true"
                                        >
                                          <div class="d-flex">
                                            <div class="flex-shrink-0">
                                              <img
                                                  src="@/assets/images/users/avatar-7.jpg"
                                                  alt=""
                                                  class="avatar-xs rounded-circle"
                                              />
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h6 class="fs-14 mb-1">
                                                Lewis Arnold
                                              </h6>
                                              <small class="text-muted"
                                              >Create new project buildng product - 10:05AM</small
                                              >
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                      <div
                                          id="collapseFive"
                                          class="accordion-collapse collapse show"
                                          aria-labelledby="headingFive"
                                          data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body ms-2 ps-5">
                                          <p class="text-muted mb-2">
                                            Every team project can have a velzon. Use the velzon to share information
                                            with your team to understand and contribute to your project.
                                          </p>
                                          <div class="avatar-group">
                                            <a
                                                href="javascript: void(0);"
                                                class="avatar-group-item"
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover"
                                                data-bs-placement="top"
                                                title=""
                                                data-bs-original-title="Christi"
                                            > <img
                                                src="@/assets/images/users/avatar-4.jpg"
                                                alt=""
                                                class="rounded-circle avatar-xs"
                                            /> </a> <a
                                              href="javascript: void(0);"
                                              class="avatar-group-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title=""
                                              data-bs-original-title="Frank Hook"
                                          > <img
                                              src="@/assets/images/users/avatar-3.jpg"
                                              alt=""
                                              class="rounded-circle avatar-xs"
                                          /> </a> <a
                                              href="javascript: void(0);"
                                              class="avatar-group-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title=""
                                              data-bs-original-title=" Ruby"
                                          >
                                            <div class="avatar-xs">
                                              <div
                                                  class="avatar-title rounded-circle bg-light text-primary"
                                              >
                                                R
                                              </div>
                                            </div>
                                          </a> <a
                                              href="javascript: void(0);"
                                              class="avatar-group-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title=""
                                              data-bs-original-title="more"
                                          >
                                            <div class="avatar-xs">
                                              <div
                                                  class="avatar-title rounded-circle"
                                              >
                                                2+
                                              </div>
                                            </div>
                                          </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--end accordion-->
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end card body -->
                        </div>
                        <!-- end card -->
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->

                  </div>
                  <!--end col-->
                </div>
              </b-tab>
              <b-tab title="Atendimentos">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title mb-3">Atendimentos</h5>
                    <div class="acitivity-timeline">
                      <div class="acitivity-item d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-1.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Oliver Phillips <span
                              class="badge bg-soft-primary text-primary align-middle"
                          >New</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            We talked about a project on linkedin.
                          </p>
                          <small class="mb-0 text-muted">Today</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                          <div
                              class="avatar-title bg-soft-success text-success rounded-circle"
                          >
                            N
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Nancy Martino <span
                              class="badge bg-soft-secondary text-secondary align-middle"
                          >In Progress</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            <i class="ri-file-text-line align-middle ms-2"></i> Create new project Buildng product
                          </p>
                          <div class="avatar-group mb-2">
                            <a
                                href="javascript: void(0);"
                                class="avatar-group-item"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Christi"
                            > <img
                                src="@/assets/images/users/avatar-4.jpg"
                                alt=""
                                class="rounded-circle avatar-xs"
                            /> </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Frank Hook"
                          > <img
                              src="@/assets/images/users/avatar-3.jpg"
                              alt=""
                              class="rounded-circle avatar-xs"
                          /> </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title=" Ruby"
                          >
                            <div class="avatar-xs">
                              <div
                                  class="avatar-title rounded-circle bg-light text-primary"
                              >
                                R
                              </div>
                            </div>
                          </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="more"
                          >
                            <div class="avatar-xs">
                              <div class="avatar-title rounded-circle">2+</div>
                            </div>
                          </a>
                          </div>
                          <small class="mb-0 text-muted">Yesterday</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-2.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Natasha Carey <span
                              class="badge bg-soft-success text-success align-middle"
                          >Completed</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            Adding a new event with attachments
                          </p>
                          <div class="row">
                            <div class="col-xxl-4">
                              <div class="row border border-dashed gx-2 p-2 mb-2">
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-2.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-3.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-4.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                              </div>
                              <!--end row-->
                            </div>
                          </div>
                          <small class="mb-0 text-muted">25 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-6.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Bethany Johnson</h6>
                          <p class="text-muted mb-2">
                            added a new member to velzon dashboard
                          </p>
                          <small class="mb-0 text-muted">19 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <div class="avatar-xs acitivity-avatar">
                            <div
                                class="avatar-title rounded-circle bg-soft-danger text-danger"
                            >
                              <i class="ri-shopping-bag-line"></i>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Your order is placed <span
                              class="badge bg-soft-danger text-danger align-middle ms-1"
                          >Out of Delivery</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            These customers can rest assured their order has been placed.
                          </p>
                          <small class="mb-0 text-muted">16 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-7.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Lewis Pratt</h6>
                          <p class="text-muted mb-2">
                            They all have something to say beyond the words on the page. They can come across as casual
                            or neutral, exotic or graphic.
                          </p>
                          <small class="mb-0 text-muted">22 Oct</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <div class="avatar-xs acitivity-avatar">
                            <div
                                class="avatar-title rounded-circle bg-soft-info text-info"
                            >
                              <i class="ri-line-chart-line"></i>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Monthly sales report</h6>
                          <p class="text-muted mb-2">
                            <span class="text-danger">2 days left</span> notification to submit the monthly sales
                                                                         report. <a
                              href="javascript:void(0);"
                              class="link-warning text-decoration-underline"
                          >Reports Builder</a
                          >
                          </p>
                          <small class="mb-0 text-muted">15 Oct</small>
                        </div>
                      </div>
                      <div class="acitivity-item d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-8.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            New ticket received <span
                              class="badge bg-soft-success text-success align-middle"
                          >Completed</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            User <span class="text-secondary">Erica245</span> submitted a ticket.
                          </p>
                          <small class="mb-0 text-muted">26 Aug</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end card-body-->
                </div>
              </b-tab>
              <b-tab title="Documentos">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import axios from "axios";
import SwiperCore, {Thumbs, Navigation, Pagination} from "swiper";
import "swiper/swiper-bundle.css";
import moment from 'moment';

SwiperCore.use([Thumbs, Navigation, Pagination]);

export default {
  name: "Usuário",
  page: {
    title: "Usuário",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Usuário",
      items: [
        {
          text: "usuario",
          href: "/",
        },
        {
          text: "Usuário",
          active: true,
        },
      ],
      URL : process.env.VUE_APP_API,
      usuario: {
        "AliquotaPadrao": null,
        "Celular": null,
        "CNPJ": null,
        "CodigoRecuperacao": null,
        "CPF": null,
        "created_at": null,
        "CRM": null,
        "DataAberturaDeConta": null,
        "DataFechamentoDeConta": null,
        "DataHoraAcesso": null,
        "DataHoraRecuperacao": null,
        "DataNascimento": null,
        "email": null,
        "HashDeConfirmacao": null,
        "HashMercadoPago": null,
        "HashValidoAte": null,
        "id": this.$route.params.id,
        "MostraAvisoCalculadora": null,
        "MPagoAlienUserId": null,
        "Nome": null,
        "NomeArquivoCarimboAssinatura": null,
        "NomeArquivoLogotipo": null,
        "NomeProfissional": null,
        "Photo": null,
        "ProcedimentosParticulares": null,
        "Sexo": null,
        "TipoUsuarioId": null,
        "UF": null,
        "updated_at": null,
        "usuario": null,
        "perfil_descricao": null,
        "verificado": 0
      }

    };
  },
  computed: {  },
  mounted() {
    this.LoadUsuario();
  },
  watch: {},
  created() {

  },
  methods: {
    async LoadUsuario() {

      const result = await axios.post(process.env.VUE_APP_API + '/v1/usuario/' + this.$route.params.id, {
        Id: this.$route.params.id
      });
      console.log(result.data);

      this.usuario = result.data;

    },
    getHumanDate : function (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    enviarEmail(){
      try{
        const consulta =  axios.post(process.env.VUE_APP_API + '/v1/usuarioemail/');
        console.log(consulta.data);
      }catch (e){
        console.log(e)
      }
    }
  },
  components: {
    Layout
  },
};
</script>